import React from "react";
import { Message } from "../../../types";
import TextMessage from "./TextMessage";
import ImageMessage from "./ImageMessage";
import FileMessage from "./FileMessage";

interface MessageContentProps {
	message: Message;
}

const MessageContent: React.FC<MessageContentProps> = ({ message }) => {
	switch (message.type_string) {
		case "TEXT":
			return <TextMessage message={message} />;
		case "IMAGE":
			return <ImageMessage message={message} />;
		case "FILE":
			return <FileMessage message={message} />;
		default:
			return <p className="text-sm">Unsupported message type</p>;
	}
};

export default MessageContent;
