import React from "react";
import { FaChevronDown, FaTrash } from "react-icons/fa";

interface MessageDropdownProps {
	isOpen: boolean;
	onToggle: () => void;
	onDelete: (e: React.MouseEvent) => void;
}

const MessageDropdown: React.FC<MessageDropdownProps> = ({
	isOpen,
	onToggle,
	onDelete,
}) => {
	return (
		<div className="relative dropdown ml-2">
			<button
				onClick={onToggle}
				className="text-gray-500 hover:text-gray-700 focus:outline-none opacity-0 group-hover:opacity-100 transition-opacity duration-200">
				<FaChevronDown className="w-3 h-3" />
			</button>
			{isOpen && (
				<div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-20">
					<button
						onClick={onDelete}
						className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">
						<FaTrash className="mr-2" /> Delete
					</button>
				</div>
			)}
		</div>
	);
};

export default MessageDropdown;
