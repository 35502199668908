import React, { useState, useRef, useEffect } from "react";
import { Message } from "../../../types";
import ImageModal from "./ImageModal";

interface ImageMessageProps {
	message: Message;
}

const ImageMessage: React.FC<ImageMessageProps> = ({ message }) => {
	const [imageLoaded, setImageLoaded] = useState(false);
	const [imageError, setImageError] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [skeletonSize, setSkeletonSize] = useState({ width: 0, height: 0 });
	const containerRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (containerRef.current) {
			const img = new Image();
			img.src = message.message_image_url;
			img.onload = () => {
				const containerWidth = containerRef.current?.clientWidth || 0;
				const aspectRatio = img.naturalWidth / img.naturalHeight;
				let width = Math.min(img.naturalWidth, containerWidth);
				let height = width / aspectRatio;

				// Limit height to a maximum of 300px
				if (height > 300) {
					height = 300;
					width = height * aspectRatio;
				}

				setSkeletonSize({ width, height });
			};
		}
	}, [message]);

	const handleImageLoad = () => setImageLoaded(true);
	const handleImageError = () => setImageError(true);
	const handleImageClick = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	return (
		<div ref={containerRef}>
			<div className="relative">
				{!imageLoaded && !imageError && (
					<div
						className="bg-gray-200 animate-pulse rounded-lg"
						style={{
							width: `${skeletonSize.width}px`,
							height: `${skeletonSize.height}px`,
							maxWidth: "100%",
							maxHeight: "300px",
						}}
					/>
				)}
				{!imageError && (
					<img
						src={message.message_image_url}
						alt={`From ${message.sender_username}`}
						className={`max-w-full max-h-[300px] w-auto h-auto rounded-lg transition-opacity duration-300 cursor-pointer ${
							imageLoaded ? "opacity-100" : "opacity-0 absolute top-0 left-0"
						}`}
						style={{
							objectFit: "contain",
						}}
						onLoad={handleImageLoad}
						onError={handleImageError}
						onClick={handleImageClick}
						loading="lazy"
					/>
				)}
			</div>
			{imageError && (
				<div className="w-full h-48 bg-gray-200 flex items-center justify-center rounded-lg">
					<p className="text-sm text-gray-500">Failed to load image</p>
				</div>
			)}
			{showModal && (
				<ImageModal
					imageUrl={message.message_image_url}
					alt={`From ${message.sender_username}`}
					onClose={handleCloseModal}
					senderName={message.sender_username}
					senderIcon={message.image_url}
				/>
			)}
			{message.message_body && (
				<p className="text-sm mb-2">{message.message_body}</p>
			)}
		</div>
	);
};

export default ImageMessage;
