import { Message, ThreadResponse } from '../types';

// Simulated API calls
export const fetchMessages = async (): Promise<ThreadResponse> => {
  // In a real app, this would be an API call
  const messages: Message[] = [
    {
      uuid: "aaf82817-1697-58bc-a6d6-523a80ac77e6",
      message_number: 314007,
      sender_uuid: "727cabc0-4286-58e0-9008-f1748d6922e5",
      sender_username: "AITest",
      message_body: "Hello! How can I assist you today?",
      is_read: true,
      message_time: "2024-10-05 08:17:15",
      image_url: "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
      file_identifier: null,
      type: 1,
      type_string: "TEXT",
      message_image_url: "https://media.mesensei.com/public/images/",
      item: null,
      document: null
    },
    {
      uuid: "b171eac3-6d3c-5078-9b6c-61e9e58bbffc",
      message_number: 314008,
      sender_uuid: "1f66c690-2e10-5b92-8d47-bb9ddf8c8393",
      sender_username: "User",
      message_body: "Hi! I have a question about React hooks.",
      is_read: true,
      message_time: "2024-10-05 08:17:52",
      image_url: "https://media.mesensei.com/public/images/Uwxp993WRJ21gMT5Sdx3Qw-8mkOToPRSw2e9Lb6nCB5mA",
      file_identifier: null,
      type: 1,
      type_string: "TEXT",
      message_image_url: "https://media.mesensei.com/public/images/",
      item: null,
      document: null
    },
    {
      uuid: "ce8a93fe-8488-5dd5-95a7-7717a77d92c3",
      message_number: 314009,
      sender_uuid: "727cabc0-4286-58e0-9008-f1748d6922e5",
      sender_username: "AITest",
      message_body: "Great! I'd be happy to help. What specific question do you have about React hooks?",
      is_read: true,
      message_time: "2024-10-05 08:18:29",
      image_url: "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
      file_identifier: null,
      type: 1,
      type_string: "TEXT",
      message_image_url: "https://media.mesensei.com/public/images/",
      item: null,
      document: null
    },
    {
      uuid: "d9b4c2a1-7e56-4f23-8a1d-3c5b9e6f8g2h",
      message_number: 314010,
      sender_uuid: "1f66c690-2e10-5b92-8d47-bb9ddf8c8393",
      sender_username: "User",
      message_body: "I'm trying to understand useEffect. Can you explain it with an example?",
      is_read: true,
      message_time: "2024-10-05 08:19:45",
      image_url: "https://media.mesensei.com/public/images/Uwxp993WRJ21gMT5Sdx3Qw-8mkOToPRSw2e9Lb6nCB5mA",
      file_identifier: null,
      type: 1,
      type_string: "TEXT",
      message_image_url: "https://media.mesensei.com/public/images/",
      item: null,
      document: null
    },
    {
      uuid: "e7f8g9h0-1i2j-3k4l-5m6n-7o8p9q0r1s2t",
      message_number: 314011,
      sender_uuid: "727cabc0-4286-58e0-9008-f1748d6922e5",
      sender_username: "AITest",
      message_body: "Certainly! Here's an example of useEffect:",
      is_read: true,
      message_time: "2024-10-05 08:20:30",
      image_url: "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
      file_identifier: null,
      type: 1,
      type_string: "TEXT",
      message_image_url: "https://media.mesensei.com/public/images/",
      item: null,
      document: null
    },
    {
      uuid: "f3g4h5i6-7j8k-9l0m-1n2o-3p4q5r7t8u",
      message_number: 314012,
      sender_uuid: "727cabc0-4286-58e0-9008-f17d6922e5",
      sender_username: "AITest",
      message_body: "This is the react icon ",
      is_read: true,
      message_time: "2024-10-05 08:21:00",
      image_url: "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
      file_identifier: null,
      type: 3,
      type_string: "IMAGE",
      message_image_url: "/logo512.png",
      item: null,
      document: null
    },
    {
      uuid: "f3g4h5i6-7j8k-9l0m-1n2o-3p4q5r6s7t8u",
      message_number: 314012,
      sender_uuid: "727cabc0-4286-58e0-9008-f1748d22e5",
      sender_username: "AITest",
      message_body: "This is the react icon ",
      is_read: true,
      message_time: "2024-10-05 08:21:00",
      image_url: "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
      file_identifier: null,
      type: 3,
      type_string: "IMAGE",
      message_image_url: "/test-vertical.png",
      item: null,
      document: null
    },
    {
      uuid: "f3g4h5i6-7j8k-sam-1n2o-3p4q5r6s7t8u",
      message_number: 314012,
      sender_uuid: "727cabc0-4286-58e008-f1748d6922e5",
      sender_username: "AITest",
      message_body: "This is the react icon ",
      is_read: true,
      message_time: "2024-10-05 08:21:00",
      image_url: "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
      file_identifier: null,
      type: 3,
      type_string: "IMAGE",
      message_image_url: "/test-horizontal.png",
      item: null,
      document: null
    },
    {
      uuid: "h7i8j9k0-1l2m-3n4o-5p6q-7r8s9t0u1v2w",
      message_number: 314014,
      sender_uuid: "727cabc0-4286-58e0-9008-f1748d6922e5",
      sender_username: "AITest",
      message_body: "Here's a PDF guide on React hooks for you.",
      is_read: true,
      message_time: "2024-10-05 08:23:30",
      image_url: "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
      file_identifier: "react_hooks_guide.pdf",
      type: 4,
      type_string: "FILE",
      message_image_url: "https://s26.q4cdn.com/900411403/files/doc_downloads/test.pdf",
      item: null,
      document: null
    },
    {
      uuid: "k2l3m4n5-6o7p-8q9r-0s1t-2u3v4w5x6y7z",
      message_number: 314015,
      sender_uuid: "727cabc0-4286-58e0-9008-f1748d6922e5",
      sender_username: "AITest",
      message_body: "And here's another PDF on advanced React patterns.",
      is_read: true,
      message_time: "2024-10-05 08:25:00",
      image_url: "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
      file_identifier: "advanced_react_patterns.pdf",
      type: 4,
      type_string: "FILE",
      message_image_url: "/example-pdfs/2024_Hilden_Making_sense_of_data.pdf",
      item: null,
      document: null
    },
    {
      uuid: "i9j0k1l2-3m4n-5o6p-7q8r-9s0t1u2v3w4x",
      message_number: 314015,
      sender_uuid: "1f66c690-2e10-5b92-8d47-bb9ddf8c8393",
      sender_username: "User",
      message_body: "Thank you so much! This is exactly what I needed.",
      is_read: true,
      message_time: "2024-10-05 08:24:45",
      image_url: "https://media.mesensei.com/public/images/Uwxp993WRJ21gMT5Sdx3Qw-8mkOToPRSw2e9Lb6nCB5mA",
      file_identifier: null,
      type: 1,
      type_string: "TEXT",
      message_image_url: "https://media.mesensei.com/public/images/",
      item: null,
      document: null
    },
  ];

  return {
    success: true,
    message: "",
    thread_uuid: "f9afe709-bf0d-5653-9bf7-4ef3aa54c7c2",
    thread_last_message_number: 314015,
    messages: messages,
    unread_messages: 0,
    first_message_number: 314007,
    last_message_number: 314015,
    first_message_time: "2024-10-05 08:17:15",
    last_message_time: "2024-10-05 08:24:45",
    total_unread_messages: 0,
    total_unread_threads: 0,
    current_workspace_missed_calls: null,
    current_workspace_unread_messages: null,
    total_app_unseen_content: 0,
    other_workspaces_unseen_content: 0
  };
};

export const sendMessage = async (content: string): Promise<Message> => {
  // In a real app, this would be an API call
  return {
    uuid: Date.now().toString(),
    message_number: 314115,
    sender_uuid: "727cabc0-4286-58e0-9008-f1748d6922e5",
    sender_username: "AITest",
    message_body: content,
    is_read: true,
    message_time: new Date().toISOString(),
    image_url: "https://media.mesensei.com/public/images/1NiGmndPQKaaM4LFaIHLWw-a86O6CKoT9+vJA6SkuUiWQ",
    file_identifier: null,
    type: 1,
    type_string: "TEXT",
    message_image_url: "https://media.mesensei.com/public/images/",
    item: null,
    document: null
  };
};