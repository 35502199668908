import React, { useState } from "react";
import { FaFileAlt, FaDownload } from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";
import { Message } from "../../../types";
import FileModal from "./FileModal";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";

// Set up the worker for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
	"pdfjs-dist/build/pdf.worker.min.mjs",
	import.meta.url
).toString();

interface FileMessageProps {
	message: Message;
}

const FileMessage: React.FC<FileMessageProps> = ({ message }) => {
	const [showModal, setShowModal] = useState(false);

	const isPDF =
		message.file_identifier?.toLowerCase().endsWith(".pdf") ?? false;

	const handleOpenModal = () => setShowModal(true);
	const handleCloseModal = () => setShowModal(false);

	return (
		<div className="flex flex-col">
			<div className="flex items-center space-x-2">
				<FaFileAlt className="text-2xl" />
				<div>
					<p className="text-sm font-semibold">{message.file_identifier}</p>
				</div>
				<a href={message.message_image_url} download className="ml-auto mr-6">
					<FaDownload className="text-lg text-gray-600 hover:text-gray-800" />
				</a>
			</div>
			{isPDF && (
				<div
					className="mt-2 border rounded-lg overflow-hidden cursor-pointer"
					onClick={handleOpenModal}>
					<Document file={message.message_image_url} className={"w-full"}>
						<Page pageNumber={1} width={300} height={200} />
					</Document>
				</div>
			)}
			{showModal && (
				<FileModal
					fileUrl={message.message_image_url}
					onClose={handleCloseModal}
				/>
			)}
		</div>
	);
};

export default FileMessage;
