import React from "react";
import { Message } from "../../types";
import { formatDate } from "../../../../utils/chat/dateFormatter";
import MessageContent from "./MessageContent/index";
import MessageDropdown from "./MessageDropdown";
import MessageWrapper from "./MessageWrapper";

interface MessageItemProps {
	message: Message;
	onDeleteMessage: (id: string) => void;
	isDropdownOpen: boolean;
	onToggleDropdown: () => void;
}

const MessageItem: React.FC<MessageItemProps> = ({
	message,
	onDeleteMessage,
	isDropdownOpen,
	onToggleDropdown,
}) => {
	const isCurrentUser = message.sender_username === "AITest"; // Replace with actual current user check

	const handleDelete = (e: React.MouseEvent) => {
		e.stopPropagation();
		onDeleteMessage(message.uuid);
	};

	return (
		<MessageWrapper isCurrentUser={isCurrentUser}>
			<div className="relative">
				<div className="flex justify-between items-start">
					<MessageContent message={message} />
					{isCurrentUser && (
						<div className="absolute top-0 right-0">
							<MessageDropdown
								isOpen={isDropdownOpen}
								onToggle={onToggleDropdown}
								onDelete={handleDelete}
							/>
						</div>
					)}
				</div>
				<p
					className={`text-xs mt-1 ${
						isCurrentUser ? "text-blue-100" : "text-gray-500"
					}`}>
					{formatDate(new Date(message.message_time))}
				</p>
			</div>
		</MessageWrapper>
	);
};

export default MessageItem;
