import React, { useRef, useEffect } from "react";
import { useChat } from "./hooks/useChat";
import MessageList from "./components/MessageList";
import InputArea from "./components/InputArea";

const ChatContainer: React.FC = () => {
	const { threadResponse, addMessage, deleteMessage, error } = useChat();
	const messagesEndRef = useRef<HTMLDivElement>(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToBottom();
	}, [threadResponse?.messages]);

	if (error) {
		return <div>Error: {error}</div>;
	}

	if (!threadResponse) {
		return <div>Loading...</div>;
	}

	return (
		<div className="flex flex-col h-screen bg-gray-50">
			<div className="flex-grow overflow-y-auto">
				<div className="w-full max-w-3xl mx-auto">
					<MessageList
						messages={threadResponse.messages}
						onDeleteMessage={deleteMessage}
					/>
					<div ref={messagesEndRef} />
				</div>
			</div>
			<InputArea onSendMessage={addMessage} />
		</div>
	);
};

export default ChatContainer;
