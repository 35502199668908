import React, { useState, useEffect } from "react";
import {
	FaDownload,
	FaSearchPlus,
	FaSearchMinus,
	FaUndo,
} from "react-icons/fa";

interface ImageModalProps {
	imageUrl: string;
	alt: string;
	onClose: () => void;
	senderName: string;
	senderIcon: string;
}

const ImageModal: React.FC<ImageModalProps> = ({
	imageUrl,
	alt,
	onClose,
	senderName,
	senderIcon,
}) => {
	const [zoom, setZoom] = useState(1);
	const [rotation, setRotation] = useState(0);

	useEffect(() => {
		const img = new Image();
		img.src = imageUrl;
	}, [imageUrl]);

	const handleDownload = () => {
		const link = document.createElement("a");
		link.href = imageUrl;
		link.download = "image.jpg"; //TODO: Change to a dynamic image name based on the image file name
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const handleZoomIn = () => setZoom((prev) => Math.min(prev + 0.1, 3));
	const handleZoomOut = () => setZoom((prev) => Math.max(prev - 0.1, 0.5));
	const handleRotate = () => setRotation((prev) => (prev + 90) % 360);

	return (
		<div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col z-50">
			<div
				className="flex-grow flex items-center justify-center"
				onClick={onClose}>
				<img
					src={imageUrl}
					alt={alt}
					className="max-h-[90vh] max-w-[90vw] object-contain"
					style={{
						transform: `scale(${zoom}) rotate(${rotation}deg)`,
						transition: "transform 0.3s ease",
					}}
					onClick={(e) => e.stopPropagation()}
				/>
			</div>
			<div className="bg-black bg-opacity-50 backdrop-blur-md p-4 flex justify-between items-center">
				<div className="flex items-center">
					<img
						src={senderIcon}
						alt={senderName}
						className="w-8 h-8 rounded-full mr-2"
					/>
					<span className="text-sm font-semibold text-white">{senderName}</span>
				</div>
				<div className="flex space-x-6">
					<button
						onClick={handleDownload}
						className="text-white hover:text-blue-400 transition-colors duration-200 focus:outline-none">
						<FaDownload size={20} />
					</button>
					<button
						onClick={handleZoomIn}
						className="text-white hover:text-blue-400 transition-colors duration-200 focus:outline-none">
						<FaSearchPlus size={20} />
					</button>
					<button
						onClick={handleZoomOut}
						className="text-white hover:text-blue-400 transition-colors duration-200 focus:outline-none">
						<FaSearchMinus size={20} />
					</button>
					<button
						onClick={handleRotate}
						className="text-white hover:text-blue-400 transition-colors duration-200 focus:outline-none">
						<FaUndo size={20} />
					</button>
				</div>
			</div>
		</div>
	);
};

export default ImageModal;
