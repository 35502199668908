import React from "react";
import { Message } from "../../../types";

interface TextMessageProps {
	message: Message;
}

const TextMessage: React.FC<TextMessageProps> = ({ message }) => {
	return <p className="text-sm">{message.message_body}</p>;
};

export default TextMessage;
