import { Link } from "react-router-dom";

function Root() {
	return (
		<div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
			<h1 className="text-4xl font-bold mb-8 text-blue-600">
				Welcome to the Home Page
			</h1>
			<nav className="space-x-4">
				<Link to="/chat">
					<button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
						Go to Chat
					</button>
				</Link>
				<Link to="/content">
					<button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
						Go to Content
					</button>
				</Link>
			</nav>
		</div>
	);
}

export default Root;
