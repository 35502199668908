import { useState, useEffect } from 'react';
import {  ThreadResponse } from "../types";
import { fetchMessages, sendMessage } from "../temp-service/chatService";

export const useChat = () => {
  const [threadResponse, setThreadResponse] = useState<ThreadResponse | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchMessages()
      .then(setThreadResponse)
      .catch(err => setError("Failed to fetch messages"));
  }, []);

  const addMessage = async (content: string) => {
    try {
      const newMessage = await sendMessage(content);
      setThreadResponse(prev => prev ? {
        ...prev,
        messages: [...prev.messages, newMessage],
        last_message_number: newMessage.message_number,
        last_message_time: newMessage.message_time
      } : null);
    } catch (err) {
      setError("Failed to send message");
    }
  };

  const deleteMessage = (uuid: string) => {
    setThreadResponse(prev => prev ? {
      ...prev,
      messages: prev.messages.filter(message => message.uuid !== uuid)
    } : null);
  };

  // Return error state
  return { threadResponse, addMessage, deleteMessage, error };
};
