import React from "react";
import { Message } from "../types";
import MessageItem from "./MessageItem/index";
import { useDropdown } from "../hooks/useDropdown";

interface Props {
	messages: Message[];
	onDeleteMessage: (id: string) => void;
}

const MessageList: React.FC<Props> = ({ messages, onDeleteMessage }) => {
	const { openId, toggle, close } = useDropdown();

	const handleDeleteMessage = (id: string) => {
		onDeleteMessage(id);
		close(); // Close the dropdown after deleting
	};

	return (
		<div className="p-4 pb-4">
			{messages.map((message: Message) => (
				<MessageItem
					key={message.uuid}
					message={message}
					onDeleteMessage={handleDeleteMessage}
					isDropdownOpen={openId === message.uuid}
					onToggleDropdown={() => toggle(message.uuid)}
				/>
			))}
		</div>
	);
};

export default MessageList;
