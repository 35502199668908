import { useState, useEffect, useCallback } from 'react';

export const useDropdown = () => {
  const [openId, setOpenId] = useState<string | null>(null);

  const toggle = useCallback((id: string) => {
    setOpenId(prevId => prevId === id ? null : id);
  }, []);

  const close = useCallback(() => setOpenId(null), []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!(event.target as Element).closest('.dropdown')) {
        close();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [close]);

  return { openId, toggle, close };
};
