import React, { useState, useCallback, useMemo } from "react";
import { motion } from "framer-motion";
import { Document, Page } from "react-pdf";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import useResizeObserver from "../../../hooks/useResizeObserver";

interface FileModalProps {
	fileUrl: string;
	onClose: () => void;
}

const resizeObserverOptions = {};
const maxWidth = 800;

const FileModal: React.FC<FileModalProps> = ({ fileUrl, onClose }) => {
	const [numPages, setNumPages] = useState<number | null>(null);
	const [pageNumber, setPageNumber] = useState(1);
	const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
	const [containerWidth, setContainerWidth] = useState<number>();

	const options = useMemo(
		() => ({
			cMapUrl: "/cmaps/",
			standardFontDataUrl: "/standard_fonts/",
		}),
		[]
	);

	const onResize = useCallback<ResizeObserverCallback>((entries) => {
		const [entry] = entries;
		if (entry) {
			setContainerWidth(entry.contentRect.width);
		}
	}, []);

	useResizeObserver(containerRef, resizeObserverOptions, onResize);

	const onDocumentLoadSuccess = useCallback(
		({ numPages }: { numPages: number }) => {
			setNumPages(numPages);
			setPageNumber(1);
		},
		[]
	);

	const changePage = useCallback((offset: number) => {
		setPageNumber((prevPageNumber) => prevPageNumber + offset);
	}, []);

	const previousPage = useCallback(() => changePage(-1), [changePage]);
	const nextPage = useCallback(() => changePage(1), [changePage]);

	const pageControls = (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ duration: 0.3 }}
			className="absolute bottom-6 left-1/2 transform -translate-x-1/2 flex items-center space-x-4 bg-white bg-opacity-90 rounded-full px-4 py-2 shadow-lg z-50">
			<motion.button
				whileHover={{ scale: 1.1 }}
				whileTap={{ scale: 0.95 }}
				onClick={previousPage}
				disabled={pageNumber <= 1}
				className="text-gray-600 disabled:text-gray-300 focus:outline-none">
				<FaChevronLeft />
			</motion.button>
			<span className="text-sm font-medium text-gray-800">
				{pageNumber} / {numPages || "--"}
			</span>
			<motion.button
				whileHover={{ scale: 1.1 }}
				whileTap={{ scale: 0.95 }}
				onClick={nextPage}
				disabled={pageNumber >= (numPages || 0)}
				className="text-gray-600 disabled:text-gray-300 focus:outline-none">
				<FaChevronRight />
			</motion.button>
		</motion.div>
	);

	return (
		<div
			className="fixed inset-0 bg-neutral-500 bg-opacity-75 flex items-center justify-center z-30"
			onClick={onClose}>
			<div
				className="relative bg-white w-full max-w-2xl mx-4"
				onClick={(e) => e.stopPropagation()}>
				<div ref={setContainerRef} className="w-full h-full rounded-lg">
					<Document
						file={fileUrl}
						onLoadSuccess={onDocumentLoadSuccess}
						options={options}>
						<Page
							key={`page_${pageNumber}`}
							pageNumber={pageNumber}
							width={
								containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth
							}
						/>
					</Document>
					{pageControls}
				</div>
			</div>
		</div>
	);
};

export default FileModal;
