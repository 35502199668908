import React from "react";

interface MessageWrapperProps {
	isCurrentUser: boolean;
	children: React.ReactNode;
}

const MessageWrapper: React.FC<MessageWrapperProps> = ({
	isCurrentUser,
	children,
}) => {
	return (
		<div
			className={`flex ${
				isCurrentUser ? "justify-end" : "justify-start"
			} mb-4 relative group`}>
			<div
				className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg ${
					isCurrentUser ? "bg-blue-500 text-white" : "bg-white text-gray-800"
				} shadow`}>
				{children}
			</div>
		</div>
	);
};

export default MessageWrapper;
